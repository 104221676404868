<template>
  <div>
    <p>{{ $t('competitions.info.points.description') }}</p>
    <ul class="mb-4">
      <li>
        <span>{{ $t('climbs.log.top') }}: </span>
        <span>{{ $t('competitions.info.points.points', { points: params.points }) }}</span>
      </li>
      <li v-if="params.points_flash > 0">
        <span>{{ $t('climbs.log.flash') }}: </span>
        <span>{{ $t('competitions.info.points.bonus', { points: params.points_flash }) }}</span>
      </li>
      <li v-if="params.points_onsight > 0">
        <span>{{ $t('climbs.log.onsight') }}: </span>
        <span>{{ $t('competitions.info.points.bonus', { points: params.points_onsight }) }}</span>
      </li>
    </ul>

    <div v-if="isEditor">
      <div class="title">Configuration</div>
      <v-text-field :value="params.points" type="number" label="Points per climb" @input="setParam('points', $event)" />
      <v-text-field
        :value="params.points_flash"
        type="number"
        label="Bonus points for flash"
        @input="setParam('points_flash', $event)"
      />
      <v-text-field
        :value="params.points_onsight"
        type="number"
        label="Bonus points for on-sight"
        @input="setParam('points_onsight', $event)"
      />
    </div>
  </div>
</template>

<script>
import scoreSystemMixin from './score-system.mixin'

export default {
  mixins: [scoreSystemMixin],
}
</script>
